// sekcja płatnikiem i odbiorcą

import React, { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import {
  ICartMainData,
  ICartMainDataUpdateRequest,
  ICommandResponseErrorField,
  ICheckoutStep
} from 'api/types';
import { ReceiverForm } from 'components/containers';
import { Checkbox, Input } from 'components/controls';

import styles from 'theme/pages/Checkout/components/Payer/Payer.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  customer: ICartMainData['customer'];
  receiver: ICartMainData['receiver'];
  dropShipping?: ICartMainData['dropshipping'];
  accessToUseDropshipping?: ICartMainData['enable_dropshipping'];
  cashOnDelivery?: ICartMainData['cash_on_delivery_value'];
  deliveryPoint?: ICartMainData['receiver_delivery_point_summary'];
  paymentMethod?: ICartMainData['payment_method'];
  deliveryMethod?: ICartMainData['delivery_method'];
  comment?: ICartMainData['comment'];
  currency?: string;
  refetchCartMainData?: () => void;
  updateCartMainData: (data: Partial<ICartMainDataUpdateRequest>) => void;
  setCheckoutStep: (step: ICheckoutStep) => void;
  isSummary?: boolean;
  error?: ICommandResponseErrorField;
  setReceiver?: Dispatch<SetStateAction<ICartMainData['receiver']>>;
}

const Payer: FC<IProps> = ({
  cartId,
  customer,
  receiver,
  refetchCartMainData,
  setCheckoutStep,
  isSummary,
  currency,
  paymentMethod,
  deliveryMethod,
  comment,
  setReceiver
}) => {
  const [additionalAddress, setAdditionalAddress] = useState(false);

  useEffect(() => {
    refetchCartMainData?.();
  }, []);

  const handleDropshipping = () => {
    setAdditionalAddress(!additionalAddress);
    // dropShipping ? disableDropshipping() : enableDropshipping();
  };

  if (!isSummary) {
    return (
      <div
        className={classnames(
          styles.componentWrapper,
          'StylePath-Pages-Checkout-Components-Payer',
          isSummary && styles.isSummary
        )}>
        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Trans>Dane do faktury</Trans>
            </div>
            <div className={styles.section}>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Nazwa firmy</Trans>*:
                </div>
                <Input disabled value={customer?.name} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Numer NIP</Trans>*:
                </div>
                <Input disabled value={customer?.tax_number} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Ulica i numer</Trans>*:
                </div>
                <Input disabled value={customer?.address.street} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Kod pocztowy</Trans>*:
                </div>

                <Input disabled value={customer?.address.postal_code} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Miasto</Trans>*:
                </div>
                <Input disabled value={customer?.address.city} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Kraj</Trans>*:
                </div>
                <Input disabled value={customer?.address.country} />
              </div>
            </div>
            <div className={styles.title}>
              <Trans>Osoba kontaktowa</Trans>
            </div>
            <div className={styles.section}>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Imię</Trans>:
                </div>
                <Input disabled value={customer?.first_name} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Nazwisko</Trans>:
                </div>
                <Input disabled value={customer?.last_name} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>Numer telefonu</Trans>:
                </div>
                <Input disabled value={customer?.phone} />
              </div>
              <div className={styles.customerDetail}>
                <div className={styles.label}>
                  <Trans>E-mail</Trans>:
                </div>
                <Input disabled value={customer?.email} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.receiverAddress}>
            <Checkbox
              size="small"
              checked={!!additionalAddress}
              onClick={() => handleDropshipping()}
            />
            <span className={styles.checkboxLabel}>
              <Trans>Inny adres dostawy</Trans>
            </span>
          </div>
          {!!additionalAddress && (
            <div className={styles.section}>
              <div className={styles.title}>
                <Trans>Adres dostawy</Trans>
              </div>
              <ReceiverForm
                id={cartId}
                receiver={receiver}
                isDropShipping
                setCheckoutStep={setCheckoutStep}
                setReceiver={setReceiver}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Payer',
        isSummary && styles.isSummary
      )}>
      <div className={styles.column}>
        <div className={styles.section}>
          <div className={styles.title}>
            <Trans>Dane do faktury</Trans>
          </div>
          <div className={styles.section}>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Nazwa firmy</Trans>:
              </span>{' '}
              <span>{customer?.name}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Numer NIP</Trans>:
              </span>{' '}
              <span>{customer?.tax_number}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Ulica i numer</Trans>:
              </span>{' '}
              <span>{customer?.address.street}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Kod pocztowy</Trans>:
              </span>{' '}
              <span>{customer?.address.postal_code}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Miasto</Trans>:
              </span>{' '}
              <span>{customer?.address.city}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Kraj</Trans>:
              </span>{' '}
              <span>{customer?.address.country}</span>
            </div>
          </div>
          <div className={styles.title}>
            <Trans>Osoba kontaktowa</Trans>
          </div>
          <div className={styles.section}>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Imię</Trans>:
              </span>{' '}
              <span>{customer?.first_name}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Nazwisko</Trans>:
              </span>{' '}
              <span>{customer?.last_name}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Numer telefonu</Trans>:
              </span>{' '}
              <span>{customer?.phone}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>E-mail</Trans>:
              </span>{' '}
              <span>{customer?.email}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.column}>
        <div className={styles.section}>
          <div className={styles.title}>
            <Trans>Adres dostawy</Trans>
          </div>
          <div className={styles.section}>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Nazwa firmy</Trans>:
              </span>{' '}
              <span>{receiver?.name}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Imię</Trans>:
              </span>{' '}
              <span>{receiver?.first_name}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Nazwisko</Trans>:
              </span>{' '}
              <span>{receiver?.last_name}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Ulica i numer</Trans>:
              </span>{' '}
              <span>{receiver?.address.street}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Kod pocztowy</Trans>:
              </span>{' '}
              <span>{receiver?.address.postal_code}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Miasto</Trans>:
              </span>{' '}
              <span>{receiver?.address.city}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Kraj</Trans>:
              </span>{' '}
              <span>{receiver?.address.country}</span>
            </div>
            <div className={styles.summaryDetail}>
              <span>
                <Trans>Numer telefonu</Trans>:
              </span>{' '}
              <span>{receiver?.phone}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.section}>
          <div className={styles.title}>
            <Trans>Sposób płatności</Trans>
          </div>
          <div className={classnames(styles.summaryDetail, styles.payment)}>
            <img src={paymentMethod?.image} />
            <span>{paymentMethod?.name}</span>&nbsp;
            <span>
              {paymentMethod?.price_net_formatted.replace('.', ',')} {currency} <Trans>netto</Trans>
            </span>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.title}>
            <Trans>Sposób dostawy</Trans>
          </div>
          <div className={classnames(styles.summaryDetail, styles.delivery)}>
            <img src={deliveryMethod?.image} />
            <span>{deliveryMethod?.name}</span>&nbsp;
            <span>
              {deliveryMethod?.price_net_formatted.replace('.', ',')} {currency}{' '}
              <Trans>netto</Trans>
            </span>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.comment}>
            <Trans>Uwagi</Trans>
          </div>
          <div className={styles.summaryDetail}>{comment}</div>
        </div>
      </div>
    </div>
  );
};

export default Payer;
