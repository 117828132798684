// przycisk z listą podglądu kkoszyków

import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import Popover from '@mui/material/Popover';

import { usePostOverLogout } from 'api';
import { useSelector, useDispatch, reduxActions } from 'store';
import { useRWD, useAppNavigate } from 'hooks';
import { Login } from 'pages';
import { DropDown } from 'components/controls';

import { UserMobileIcon, UserLargeIcon, UserWhiteIcon } from 'assets/icons';

import styles from 'theme/components/containers/LoginButton/LoginButton.module.scss';

// typ danych wejściowych
interface IProps {
  onClose?: () => void;
  isHomePage?: boolean;
}

const ButtonCart: FC<IProps> = ({ onClose, isHomePage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { profile, overloginUserId } = useSelector((state) => state.auth);

  const { isMobile } = useRWD();

  const navigate = useAppNavigate();

  const { mutateAsync } = usePostOverLogout();

  // wylogowanie uźytkownika z systemu
  const handleLogout = async () => {
    if (overloginUserId) {
      await mutateAsync();

      if (pathname.includes('/cart/')) {
        navigate('/cart/0');
      }

      dispatch(reduxActions.setCurrentCartId(null));
      dispatch(reduxActions.setOverlogin(null));

      return;
    }
    dispatch(reduxActions.signOut());
    navigate('/');
  };

  // czy jest ustawiony element HTML popovera (użyte przy pokazywaniu modala)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);

  const renderContent = () => {
    if (profile?.role === 'ROLE_OPEN_PROFILE') {
      return (
        <>
          <button
            className={styles.authButton}
            onClick={(event) => {
              onClose?.();
              setPopoverAnchor(event.currentTarget);
            }}>
            {isMobile ? <UserMobileIcon /> : isHomePage ? <UserWhiteIcon /> : <UserLargeIcon />}
          </button>

          <Popover
            anchorEl={popoverAnchor}
            classes={{ paper: styles.paper }}
            open={!!popoverAnchor}
            onClose={() => setPopoverAnchor(null)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            marginThreshold={50}>
            <Login isPopover setPopoverAnchor={setPopoverAnchor} />
          </Popover>
        </>
      );
    }

    return (
      <div className={styles.myAccountButton}>
        <DropDown
          label={
            <button className={classnames(styles.authButton, styles.logged)}>
              {isMobile ? <UserMobileIcon /> : isHomePage ? <UserWhiteIcon /> : <UserLargeIcon />}
            </button>
          }
          isListType
          isMyAccount
          items={[
            {
              label: t('Moje konto'),
              onClick: () => navigate('/dashboard')
            },
            {
              label: t('Zamówienia'),
              onClick: () => navigate('/dashboard/orders')
            },
            {
              label: t('Dokumenty i płatności'),
              onClick: () => navigate('/dashboard/documents')
            },
            {
              label: t('Finanse'),
              onClick: () => navigate('/dashboard/finances')
            },
            // {
            //   label: t('Ulubione'),
            //   onClick: () => navigate('/dashboard/favourites')
            // },
            {
              label: t('Moje dane'),
              onClick: () => navigate('/dashboard/my-account')
            },
            {
              label: overloginUserId ? t('Wróć na konto') : t('Wyloguj się'),
              onClick: () => {
                handleLogout();
              }
            }
          ]}
          withDropdownIcon={false}
        />
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-LoginButton'
      )}>
      {renderContent()}
    </div>
  );
};

export default ButtonCart;
