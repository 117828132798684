import React, { FC, useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Popover from '@mui/material/Popover';

import { usePostOnlinePaymentInitialize, useGetOrderInformationVmp } from 'api';
import { IOrderVmp } from 'api/types';
import { Modal } from 'components/controls';
import { ReceiverForm } from 'components/containers';
import { CommentEdit } from './components';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
  refetchOrderData: () => void;
  isEditingMode: boolean;
}

const OrderSummary: FC<IProps> = ({ orderData, refetchOrderData, isEditingMode }) => {
  const { t } = useTranslation();

  // czy jest otwarty modal zmiany adresu
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  //inicjalizacja metody płatności
  const { mutate: initializePayment } = usePostOnlinePaymentInitialize({
    onSuccess: ({ data }) => {
      if (data.redirect_type === 'REDIRECT_URL') {
        window.location.replace(data.redirect_payload);
      }
    }
  });

  const { data: orderInformation } = useGetOrderInformationVmp();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePaymentButtonClick = (
    type: null | string,
    id: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (type === 'INITIALIZE_PAYMENT') {
      initializePayment({
        context: 'ORDER_DETAILS',
        entity_type: 'ORDER',
        entity_id: id
      });
    }

    if (type === 'SHOW_BANK_DETAILS') {
      handleClick(event);
    }
  };

  const receiver = useMemo(
    () => ({
      id: orderData.id,
      name: orderData.receiver_name,
      first_name: orderData.receiver_first_name,
      last_name: orderData.receiver_last_name,
      email: orderData.receiver_email,
      phone: orderData.receiver_phone,
      address: {
        street: orderData.receiver_address.street,
        postal_code: orderData.receiver_address.postal_code,
        city: orderData.receiver_address.city,
        building: orderData.receiver_address.building,
        apartment: orderData.receiver_address.apartment,
        country: orderData.receiver_address.country_code,
        country_code: orderData.receiver_address.country_code
      }
    }),
    [orderData]
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Order-components-OrderSummary'
      )}>
      <div className={styles.columnsWrapper}>
        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Trans>Dane do faktury</Trans>
            </div>
            <div className={styles.section}>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Nazwa firmy</Trans>:
                </span>{' '}
                <span>{orderData?.client_name}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Numer NIP</Trans>:
                </span>{' '}
                <span>{orderData?.client_tax_number}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Ulica i numer</Trans>:
                </span>{' '}
                <span>{orderData?.client_address.street}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Kod pocztowy</Trans>:
                </span>{' '}
                <span>{orderData?.client_address.postal_code}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Miasto</Trans>:
                </span>{' '}
                <span>{orderData?.client_address.city}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Kraj</Trans>:
                </span>{' '}
                <span>{orderData?.client_address.country}</span>
              </div>
            </div>
            <div className={styles.title}>
              <Trans>Osoba kontaktowa</Trans>
            </div>
            <div className={styles.section}>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Imię</Trans>:
                </span>{' '}
                <span>{orderData.client_first_name}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Nazwisko</Trans>:
                </span>{' '}
                <span>{orderData.client_last_name}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Numer telefonu</Trans>:
                </span>{' '}
                <span>{orderData.client_phone}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>E-mail</Trans>:
                </span>{' '}
                <span>{orderData.client_email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Trans>Adres dostawy</Trans>
            </div>
            <div className={styles.section}>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Nazwa firmy</Trans>:
                </span>{' '}
                <span>{receiver?.name}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Imię</Trans>:
                </span>{' '}
                <span>{receiver?.first_name}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Nazwisko</Trans>:
                </span>{' '}
                <span>{receiver?.last_name}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Ulica i numer</Trans>:
                </span>{' '}
                <span>{receiver?.address.street}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Kod pocztowy</Trans>:
                </span>{' '}
                <span>{receiver?.address.postal_code}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Miasto</Trans>:
                </span>{' '}
                <span>{receiver?.address.city}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Kraj</Trans>:
                </span>{' '}
                <span>{receiver?.address.country}</span>
              </div>
              <div className={styles.summaryDetail}>
                <span>
                  <Trans>Numer telefonu</Trans>:
                </span>{' '}
                <span>{receiver?.phone}</span>
              </div>
            </div>
          </div>
          {isEditingMode && (
            <button
              className={classnames(styles.changeBtn, styles.bottom)}
              onClick={() => setIsModalOpen(true)}>
              <Trans>Zmień</Trans>
            </button>
          )}
        </div>
        <div className={classnames(styles.column, styles.paymentDeliveryWrapper)}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Trans>Sposób płatności</Trans>
            </div>
            <div className={styles.summaryDetail}>
              <img src={orderData.payment_method.image} />
              <span className={styles.name}>{orderData.payment_method?.name}</span>
              <span>
                {orderData.payment_method?.value_net_formatted.replace('.', ',')}{' '}
                {orderData.currency} <Trans>netto</Trans>
              </span>
            </div>
            <div className={styles.paymentStatus}>
              <button
                onClick={(e) =>
                  handlePaymentButtonClick(
                    orderData.status_payment_formatted.button_type,
                    orderData.id,
                    e
                  )
                }
                style={{ color: orderData.status_payment_formatted.color }}>
                {orderData.status_payment_formatted.name}
              </button>
              <img src={orderData.status_payment_formatted.icon} />
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}>
              <div
                className={styles.popoverContent}
                dangerouslySetInnerHTML={{ __html: orderInformation?.information || '' }}
              />
            </Popover>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <Trans>Sposób dostawy</Trans>
            </div>
            <div className={styles.summaryDetail}>
              <img src={orderData.delivery_method_vmp.image} />
              <span className={styles.name}>{orderData.delivery_method_vmp.name}</span>
              <span>
                {orderData.delivery_method_vmp.value_net_formatted.replace('.', ',')}{' '}
                {orderData.currency} <Trans>netto</Trans>
              </span>
            </div>
            {orderData.delivery?.map((delivery, i) => (
              <div className={styles.trackingNumber} key={i}>
                <Trans>Numer przesyłki</Trans>: {delivery.tracking_number}
              </div>
            ))}
            <div className={styles.paymentStatus}></div>
          </div>
          <div className={styles.section}>
            <CommentEdit
              orderData={orderData}
              refetchOrderData={refetchOrderData}
              isEditingMode={isEditingMode}
            />
          </div>
        </div>
      </div>
      {/* {isMobile && (
        <div className={classnames(styles.title, styles.mainTitle)}>ID{orderData.id}D</div>
      )}
      <div className={styles.blocks}>
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid className={styles.box} item xs={12} lg={4}>
            <div className={styles.boxTitle}>
              <Trans>Dane do faktury</Trans>
            </div>
            <div className={styles.customer}>
              {orderData.client_name}
              <br />
              {orderData.client_first_name} {orderData.client_last_name}
              <br />
              {orderData.client_address?.street} {orderData.client_address?.building}
              {orderData.client_address.apartment && '/'}
              {orderData.client_address?.apartment}
              <br />
              {orderData.client_address?.postal_code} {orderData.client_address?.city}
              <br />
              <br />
              <span className={styles.label}>
                <Trans>NIP</Trans>:
              </span>{' '}
              {orderData.client_tax_number}
              <br />
              <span className={styles.label}>
                <Trans>E-mail</Trans>:
              </span>{' '}
              {orderData.client_email}
              <br />
              <span className={styles.label}>
                <Trans>Telefon</Trans>:
              </span>{' '}
              {orderData.client_phone}
            </div>
          </Grid>
          <Grid className={styles.box} item xs={12} lg={4}>
            <div className={styles.inner}>
              <div className={styles.boxTitle}>
                <Trans>Dane odbiorcy</Trans>
                {isEditingMode && orderData.dropshipping && (
                  <button className={styles.changeBtn} onClick={() => setIsModalOpen(true)}>
                    <Trans>Zmień</Trans>
                  </button>
                )}
              </div>
              <div className={styles.receiver}>
                {orderData.receiver_name_to_show}
                <br />
                {orderData.receiver_first_name} {orderData.receiver_last_name}
                <br />
                {orderData.receiver_address.street} {orderData.receiver_address.building}
                {orderData.receiver_address.apartment && '/'}
                {orderData.receiver_address.apartment}
                <br />
                {orderData.receiver_address.postal_code} {orderData.receiver_address.city}
                <br />
                <br />
                <span className={styles.label}>
                  <Trans>Email</Trans>:
                </span>{' '}
                {orderData.receiver_email}
                <br />
                <span className={styles.label}>
                  <Trans>Telefon</Trans>:
                </span>{' '}
                {orderData.receiver_phone}
              </div>
            </div>
          </Grid>
          <Grid className={styles.box} item xs={12} lg={4}>
            <div className={styles.inner}>
              <div className={styles.boxTitle}>
                <Trans>Data utworzenia</Trans>
              </div>
              <div>
                {orderData?.create_date
                  ? format(new Date(orderData.create_date), 'dd-MM-yyyy')
                  : '---'}
              </div>
              {!isMobile && <br />}
              <div className={styles.boxTitle}>
                <Trans>Wartość zamówienia</Trans>
              </div>
              <div>
                {orderData.value_gross_formatted.replace('.', ',')} {orderData.currency}{' '}
                <Trans>brutto</Trans>
              </div>
            </div>
          </Grid>
          <DeliveryEdit
            orderData={orderData}
            refetchOrderData={refetchOrderData}
            isEditingMode={isEditingMode}
          />
          <PaymentEdit orderData={orderData} isEditingMode={isEditingMode} />
          {orderData.is_editable ? (
            <CommentEdit
              orderData={orderData}
              refetchOrderData={refetchOrderData}
              isEditingMode={isEditingMode}
            />
          ) : (
            <DownloadSection orderData={orderData} />
          )}
        </Grid>
      </div> */}
      {isModalOpen && (
        <Modal title={t('Edytuj dane odbiorcy')} onClose={() => setIsModalOpen(false)}>
          <ReceiverForm
            id={orderData.id}
            receiver={receiver}
            isDropShipping={orderData.dropshipping}
            isEditOrder
            onCancelButtonClick={() => setIsModalOpen(false)}
            refetchOrderData={refetchOrderData}
          />
        </Modal>
      )}
    </div>
  );
};

export default OrderSummary;
