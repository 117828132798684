// widok pozycji w wersji default

import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { IColumn } from 'components/controls/Table';
import { IProductVersion, IUnit, IPosition, IProduct } from 'api/types';

import SizingTableQty from './SizingTableQty';

import styles from 'theme/pages/Product/components/SizingTable/SizingTable.module.scss';

export const getSimpleColumns = ({
  isMobile,
  unit,
  positions,
  product,
  changeProductPositions,
  onClickSizingTable
}: {
  renderCell: (arg: number) => ReactElement;
  isMobile: boolean;
  unit?: IUnit;
  product: IProduct;
  positions: IPosition[];
  changeProductPositions: (arg: IPosition) => void;
  onClickSizingTable: () => void;
}): IColumn<IProductVersion>[] =>
  isMobile
    ? [
        {
          title: <Trans>Rozmiar</Trans>,
          key: 'size',
          align: 'left',
          renderCell: (item) => <div className={styles.cell}>{item.size}</div>,
          width: 65
        },
        {
          title: product.sizing_table && (
            <div className={styles.sizingTableLabel} onClick={() => onClickSizingTable()}>
              <Trans>Tabela rozmiarów</Trans>
            </div>
          ),
          key: 'qty',
          align: 'right',
          renderCell: (item) =>
            unit && (
              <SizingTableQty
                productId={item.product_id}
                unitId={unit.unit_id}
                onChangeQty={changeProductPositions}
                isNoSelected={!positions.length}
              />
            )
        }
      ]
    : [
        {
          title: <Trans>Rozmiar</Trans>,
          key: 'size',
          align: 'center',
          renderCell: (item) => <div className={styles.cell}>{item.size}</div>
        },
        {
          title: <Trans>Kontraktacja</Trans>,
          key: 'qty',
          align: 'center',
          renderCell: (item) =>
            unit && (
              <SizingTableQty
                productId={item.product_id}
                unitId={unit.unit_id}
                onChangeQty={changeProductPositions}
                isNoSelected={!positions.length}
                fullWidth
              />
            )
        }
      ];

export default getSimpleColumns;
