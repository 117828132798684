import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useFormik } from 'formik';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import map from 'lodash/map';

import { useSelector } from 'store';
import { useGetUserProfileVmp, usePutUserProfileVmp, useGetUsersCountries } from 'api';
import { IUsersCountryListItem } from 'api/types';
import { Input, Select } from 'components/controls';

import styles from 'theme/components/containers/UserForm/UserForm.module.scss';

interface ITitleProps extends PropsWithChildren {
  secondary?: boolean;
}

// typ danych wejściowych
interface IProps {
  isRegisterForm?: boolean;
}

const UserForm: FC<IProps> = () => {
  const { profile: profileData } = useSelector((state) => state.auth);

  const { data: profile, refetch: refetchUserProfileData } = useGetUserProfileVmp();

  // aktualizacja danych profilu
  const { mutate: changeProfile } = usePutUserProfileVmp(profile?.id || 0, {
    onSuccess: () => {
      refetchUserProfileData();
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // pobieranie listy możliwych krajów
  const { data: articleCountries } = useGetUsersCountries();

  // opcje dropdownu typów pola
  const countryOptions = useMemo(() => {
    const countries = (articleCountries?.items || []).map((country) => ({
      value: country.code,
      label: <span>{country.name}</span>,
      item: country
    }));

    return countries;
  }, [articleCountries]);

  // obsługa danych formularza
  const { values, errors, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      first_name: profile?.customer.first_name || '',
      last_name: profile?.customer.last_name || '',
      tax_number: profile?.customer.nip || '',
      phone: profile?.contact_data?.phone || '',
      billing_address: {
        name: profile?.primary_shipping_address?.name || '',
        street: profile?.customer.address?.street || '',
        building: profile?.customer.address?.building || '',
        postal_code: profile?.customer.address?.postal_code || '',
        city: profile?.customer.address?.city || '',
        country: profile?.customer.address?.country || ''
      },
      receiver_address: {
        name: profile?.primary_shipping_address?.name || '',
        street: profile?.primary_shipping_address?.address.street || '',
        building: profile?.primary_shipping_address?.address.building || '',
        postal_code: profile?.primary_shipping_address?.address.postal_code || '',
        city: profile?.primary_shipping_address?.address.city || '',
        country_code: profile?.primary_shipping_address?.address.country_code || '',
        first_name: profile?.primary_shipping_address?.first_name || '',
        last_name: profile?.primary_shipping_address?.last_name || '',
        phone: profile?.primary_shipping_address?.phone || ''
      }
    },
    onSubmit: (values) => {
      const {
        receiver_address: {
          name,
          street,
          postal_code,
          city,
          country_code,
          first_name,
          last_name,
          building
        }
      } = values;

      changeProfile({
        primary_shipping_address: {
          name,
          address: {
            street,
            postal_code,
            city,
            country_code,
            building
          }
        },
        contact_person_first_name: first_name,
        contact_person_last_name: last_name
      });
    },
    validateOnChange: false,
    enableReinitialize: true
  });

  const Title: FC<ITitleProps> = ({ children, secondary }) => (
    <div className={classnames(styles.title, { [styles.secondary]: secondary })}>{children}</div>
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.accountWrapper}>
        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Title>
                <Trans>Dane do faktury</Trans>
              </Title>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwa firmy</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.name}
                onChange={(value) => setFieldValue('billing_address.name', value)}
                error={errors?.billing_address?.name}
                disabled
              />
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Numer NIP</Trans>*
              </span>

              <Input
                type="text"
                value={values.tax_number}
                onChange={(value) => setFieldValue('tax_number', value)}
                error={errors?.tax_number}
                disabled
              />
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Ulica</Trans>*
              </span>

              <div className={styles.street}>
                <Input
                  type="text"
                  value={values.billing_address.street}
                  onChange={(value) => setFieldValue('billing_address.street', value)}
                  error={errors?.billing_address?.street}
                  disabled
                />
                <div className={styles.customerDetail}>
                  <span className={styles.label}>
                    <Trans>Numer lokalu</Trans>*
                  </span>

                  <Input
                    type="text"
                    value={values.billing_address.building}
                    onChange={(value) => setFieldValue('billing_address.building', value)}
                    error={errors?.billing_address?.building}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.postal_code}
                onChange={(value) => setFieldValue('billing_address.postal_code', value)}
                error={errors?.billing_address?.postal_code}
                disabled
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Miasto</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.city}
                onChange={(value) => setFieldValue('billing_address.city', value)}
                error={errors?.billing_address?.city}
                disabled
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kraj</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.country}
                onChange={(value) => setFieldValue('billing_address.country', value)}
                error={errors?.billing_address?.country}
                disabled
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <Title>
                <Trans>Osoba kontaktowa</Trans>
              </Title>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Imię</Trans>*
              </span>

              <Input
                type="text"
                value={values.first_name}
                onChange={(value) => setFieldValue('first_name', value)}
                error={errors.first_name}
                disabled
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwisko</Trans>*
              </span>

              <Input
                type="text"
                value={values.last_name}
                onChange={(value) => setFieldValue('last_name', value)}
                error={errors.last_name}
                disabled
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Telefon</Trans>*
              </span>

              <Input
                type="text"
                value={values.phone}
                onChange={(value) => setFieldValue('phone', value)}
                error={errors.phone}
                disabled
              />
            </div>
          </div>
          <div className={styles.contact}>
            <a href={`mailto:${profileData?.email_to_contact_owner_store}`}>
              <Trans>Skontaktuj się, aby zmienić dane</Trans>
            </a>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Title>
                <Trans>Adres dostawy</Trans>
              </Title>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwa firmy</Trans>
              </span>

              <Input
                type="text"
                value={values.receiver_address.name}
                onChange={(value) => setFieldValue('receiver_address.name', value)}
                error={errors?.receiver_address?.name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Imię</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_address.first_name}
                onChange={(value) => setFieldValue('receiver_address.first_name', value)}
                error={errors?.receiver_address?.first_name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwisko</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_address.last_name}
                onChange={(value) => setFieldValue('receiver_address.last_name', value)}
                error={errors?.receiver_address?.last_name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Ulica</Trans>*
              </span>

              <div className={styles.street}>
                <Input
                  type="text"
                  value={values.receiver_address.street}
                  onChange={(value) => setFieldValue('receiver_address.street', value)}
                  error={errors.receiver_address?.street}
                />
                <div className={styles.customerDetail}>
                  <span className={styles.label}>
                    <Trans>Numer lokalu</Trans>*
                  </span>

                  <Input
                    type="text"
                    value={values.receiver_address.building}
                    onChange={(value) => setFieldValue('receiver_address.building', value)}
                    error={errors.receiver_address?.building}
                  />
                </div>
              </div>
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_address.postal_code}
                onChange={(value) => setFieldValue('receiver_address.postal_code', value)}
                error={errors.receiver_address?.postal_code}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Miasto</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_address.city}
                onChange={(value) => setFieldValue('receiver_address.city', value)}
                error={errors.receiver_address?.city}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kraj</Trans>*
              </span>

              <div>
                <Select<IUsersCountryListItem>
                  options={countryOptions}
                  variant="bordered"
                  value={values.receiver_address.country_code}
                  onChange={(item) => {
                    if (item?.code) {
                      setFieldValue('receiver_address.country_code', item.code);
                    }
                  }}
                  error={errors.receiver_address?.country_code}
                />
              </div>
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Telefon</Trans>*
              </span>

              <div>
                <Input
                  type="text"
                  value={values.receiver_address.phone}
                  onChange={(value) => setFieldValue('receiver_address.phone', value)}
                  error={errors.receiver_address?.phone}
                />
              </div>
            </div>
            <div className={classnames(styles.section, styles.changeButton)}>
              <div>
                <button type="submit">
                  <Trans>Zmień</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
