/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useFormik } from 'formik';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import map from 'lodash/map';

import { usePostUserRegister, useGetUsersCountries } from 'api';
import { IUsersCountryListItem } from 'api/types';
import { useRWD, useAppNavigate } from 'hooks';
import { Button, Input, Checkbox, Select } from 'components/controls';

import styles from 'theme/components/containers/UserForm/UserForm.module.scss';

interface ITitleProps extends PropsWithChildren {
  secondary?: boolean;
}

const UserForm = () => {
  const { isMobile } = useRWD();
  const navigate = useAppNavigate();

  // aktualizacja danych profilu
  const { mutate: createProfile, isLoading: isCreatingProfile } = usePostUserRegister({
    onSuccess: (data) => {
      resetForm();
      navigate('/?thankYouRegister=true', { state: { message: data.message } });
    },
    onError: (errors) => {
      map(errors.fields_info, (field) => setFieldError(field.property_path, field.message));
    }
  });

  // pobieranie listy możliwych krajów
  const { data: articleCountries } = useGetUsersCountries();

  // obsługa danych formularza
  const { values, errors, setFieldValue, handleSubmit, setFieldError, resetForm } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      tax_number: '',
      phone: '',
      email: '',
      password: '',
      password_confirm: '',
      recaptcha_token: '',
      billing_address: {
        name: '',
        street: '',
        building: ' ',
        apartment: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        country_code: ''
      },
      receiver_first_name: '',
      receiver_last_name: '',
      receiver_address: {
        name: '',
        street: '',
        building: ' ',
        apartment: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        country_code: ''
      },
      agreements: [
        {
          symbol: 'rules',
          accepted: false
        },
        {
          symbol: 'newsletter_momenti',
          accepted: false
        }
      ]
    },
    onSubmit: async (values) => {
      createProfile(values);
    },
    validateOnChange: false,
    enableReinitialize: true
  });

  // opcje dropdownu typów pola
  const countryOptions = useMemo(() => {
    const countries = (articleCountries?.items || []).map((country) => ({
      value: country.code,
      label: <span>{country.name}</span>,
      item: country
    }));

    return countries;
  }, [articleCountries]);

  const Title: FC<ITitleProps> = ({ children, secondary }) => (
    <div className={classnames(styles.title, { [styles.secondary]: secondary })}>{children}</div>
  );

  const renderPassword = () => (
    <>
      <div>
        <span className={styles.label}>
          <Trans>Hasło</Trans>*
        </span>
        <Input
          type="password"
          value={values.password}
          onChange={(value) => setFieldValue('password', value)}
          error={errors.password}
          autoComplete="new-password"
        />
      </div>

      <div>
        <span className={styles.label}>
          <Trans>Powtórz hasło</Trans>*
        </span>
        <Input
          type="password"
          value={values.password_confirm}
          onChange={(value) => setFieldValue('password_confirm', value)}
          error={errors.password_confirm}
          autoComplete="off"
        />
      </div>
    </>
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.accountWrapper}>
        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Title>
                <Trans>Dane do faktury</Trans>
              </Title>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwa firmy</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.name}
                onChange={(value) => setFieldValue('billing_address.name', value)}
                error={errors?.billing_address?.name}
              />
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Numer NIP</Trans>*
              </span>

              <Input
                type="text"
                value={values.tax_number}
                onChange={(value) => setFieldValue('tax_number', value)}
                error={errors?.tax_number}
              />
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Ulica</Trans>*
              </span>

              <div className={styles.street}>
                <Input
                  type="text"
                  value={values.billing_address.street}
                  onChange={(value) => setFieldValue('billing_address.street', value)}
                  error={errors?.billing_address?.street}
                />
                <div className={styles.customerDetail}>
                  <span className={styles.label}>
                    <Trans>Numer lokalu</Trans>*
                  </span>

                  <Input
                    type="text"
                    value={values.billing_address.building}
                    onChange={(value) => setFieldValue('billing_address.building', value)}
                    error={errors?.billing_address?.building}
                  />
                </div>
              </div>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.postal_code}
                onChange={(value) => setFieldValue('billing_address.postal_code', value)}
                error={errors?.billing_address?.postal_code}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Miasto</Trans>*
              </span>

              <Input
                type="text"
                value={values.billing_address.city}
                onChange={(value) => setFieldValue('billing_address.city', value)}
                error={errors?.billing_address?.city}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kraj</Trans>*
              </span>

              <div>
                <Select<IUsersCountryListItem>
                  options={countryOptions}
                  variant="bordered"
                  value={values.billing_address.country_code}
                  onChange={(item) => {
                    if (item?.code) {
                      setFieldValue('billing_address.country_code', item.code);
                    }
                  }}
                  error={errors?.billing_address?.country_code}
                />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <Title>
                <Trans>Osoba kontaktowa</Trans>
              </Title>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Imię</Trans>*
              </span>

              <Input
                type="text"
                value={values.first_name}
                onChange={(value) => setFieldValue('first_name', value)}
                error={errors.first_name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwisko</Trans>*
              </span>

              <Input
                type="text"
                value={values.last_name}
                onChange={(value) => setFieldValue('last_name', value)}
                error={errors.last_name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Telefon</Trans>*
              </span>

              <Input
                type="text"
                value={values.phone}
                onChange={(value) => setFieldValue('phone', value)}
                error={errors.phone}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Email</Trans>*
              </span>
              <Input
                type="text"
                onChange={(value) => setFieldValue('email', value)}
                value={values.email}
                error={errors.email}
              />
            </div>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Title>
                <Trans>Dane do wysyłki</Trans>
              </Title>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwa firmy</Trans>
              </span>

              <Input
                type="text"
                value={values.receiver_address.name}
                onChange={(value) => setFieldValue('receiver_address.name', value)}
                error={errors?.receiver_address?.name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Imię</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_first_name}
                onChange={(value) => setFieldValue('receiver_first_name', value)}
                error={errors?.receiver_first_name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Nazwisko</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_last_name}
                onChange={(value) => setFieldValue('receiver_last_name', value)}
                error={errors?.receiver_last_name}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Ulica</Trans>*
              </span>

              <div className={styles.street}>
                <Input
                  type="text"
                  value={values.receiver_address.street}
                  onChange={(value) => setFieldValue('receiver_address.street', value)}
                  error={errors?.receiver_address?.street}
                />
                <div className={styles.customerDetail}>
                  <span className={styles.label}>
                    <Trans>Numer lokalu</Trans>*
                  </span>

                  <Input
                    type="text"
                    value={values.receiver_address.building}
                    onChange={(value) => setFieldValue('receiver_address.building', value)}
                    error={errors?.receiver_address?.building}
                  />
                </div>
              </div>
            </div>

            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kod pocztowy</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_address.postal_code}
                onChange={(value) => setFieldValue('receiver_address.postal_code', value)}
                error={errors.receiver_address?.postal_code}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Miasto</Trans>*
              </span>

              <Input
                type="text"
                value={values.receiver_address.city}
                onChange={(value) => setFieldValue('receiver_address.city', value)}
                error={errors.receiver_address?.city}
              />
            </div>
            <div className={styles.customerDetail}>
              <span className={styles.label}>
                <Trans>Kraj</Trans>*
              </span>

              <div>
                <Select<IUsersCountryListItem>
                  options={countryOptions}
                  variant="bordered"
                  value={values.receiver_address.country_code}
                  onChange={(item) => {
                    if (item?.code) {
                      setFieldValue('receiver_address.country_code', item.code);
                    }
                  }}
                  error={errors.receiver_address?.country_code}
                />
              </div>
            </div>
          </div>

          {isMobile && (
            <div className={styles.section}>
              <div className={styles.title}>
                <Title>
                  <Trans>Hasło</Trans>
                </Title>
              </div>
              {renderPassword()}
            </div>
          )}

          <div className={styles.section}>
            <div>
              <label className={styles.consent}>
                <Checkbox
                  checked={values.agreements[0].accepted}
                  size="small"
                  onClick={() =>
                    setFieldValue('agreements[0]', {
                      symbol: 'rules',
                      accepted: !values.agreements[0].accepted
                    })
                  }
                />
                <span className={styles.label}>
                  <Trans>Oświadczam, że znam i akceptuję postanowienia Regulaminu.</Trans>*
                </span>
              </label>
              <span className={styles.error}>{(errors.agreements?.[0] as any)?.accepted}</span>
            </div>
            <div>
              <label className={styles.consent}>
                <Checkbox
                  checked={values.agreements[1].accepted}
                  size="small"
                  onClick={() =>
                    setFieldValue('agreements[1]', {
                      symbol: 'newsletter_momenti',
                      accepted: !values.agreements[1].accepted
                    })
                  }
                />
                <span className={styles.label}>
                  <Trans>Chcę otrzymywać Newsletter Momenti Per Me.</Trans>
                </span>
              </label>
              <span className={styles.error}>{(errors.agreements?.[1] as any)?.accepted}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.heading}>
        <h2>
          <Trans>Nadaj hasło</Trans>
        </h2>
      </div>
      <div className={classnames(styles.section, styles.actionWrapper)}>
        {!isMobile && renderPassword()}
        <div>
          <Button htmlType="submit" loading={isCreatingProfile}>
            <Trans>Zarejestruj</Trans>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
